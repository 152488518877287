import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router"
import { Fragment, useEffect, useState } from "react";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MobileNav from "./Mobile";
import SearchBox from "../Product/Search/Box";
import AdsBanner from "./AdsBanner";
import { useSelector } from "react-redux";
import { Badge, IconButton, SwipeableDrawer } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import Cart from "../Cart";
import { NumericFormat } from "react-number-format";
import { useCart } from "@/hooks/cart";

const car_type = [
    {
        id: 1,
        category: "Light Truck",
        slug: "Light Truck",
    },
    {
        id: 2,
        category: "Sedan",
        slug: "Sedan",
    },
    {
        id: 3,
        category: "SUV",
        slug: "SUV",
    },
    {
        id: 4,
        category: "Van and Light Truck",
        slug: "Van and Light Truck",
    },
];

const tires_brand = [
    {
        id: 1,
        name: "Budget",
        slug: "budget-tires",
    },
    {
        id: 2,
        name: "Economy",
        slug: "economy-tires",
    },
    {
        id: 3,
        name: "Mid Range",
        slug: "mid-range-tires",
    },
    {
        id: 4,
        name: "Premium",
        slug: "premium-tires",
    }
];

const navLinks = [
    {
        name: "Shop",
        url: "/shop",
        menu: [
            {
                name: "All Products",
                url: "/shop"
            },
            {
                name: "Car Type",
                url: "/",
                sub_menu: car_type
            },
            {
                name: "Price Category",
                url: "/",
                sub_menu: tires_brand
            },
        ]
    },
    {
        name: "Brands",
        url: "/brands"
    },
    {
        name: "Promos",
        url: "/promos"
    },
    {
        name: "About Us",
        url: "/about-us"
    },
    {
        name: "Installation Partners",
        url: "/installation-partners"
    },
    {
        name: "Become a Partner",
        url: "/become-a-partner"
    },
    {
        name: "FAQ",
        url: "/faq"
    },
];
const mobileNavigation = [
    { name: 'Shop', href: '/shop'},
    { name: 'Brands', href: '/brands'},
    { name: 'Promos', href: '/promo'},
    { name: 'About Us', href: '/about-us'},
    { name: 'Installation Partner', href: '/installation-partners'},
    { name: 'Become a Partner', href: '/become-a-partner'},
    { name: 'FAQ', href: '/faq'},
    { name: 'CART', href: '/'},
    { name: 'HELP', href: '/'},
    { name: 'FOLLOW', href: '/'},
    { name: 'How to Buy', href: '/how-to-buy'},
    { name: 'Privacy Policy', href: '/privacy-policy'},
    { name: 'Terms and Condition', href: '/terms-and-conditions'},
];

export default function Navigation(){
    const router = useRouter();
    const cartItems = useSelector((state) => state.cart);
    const {totalAmount, totalPromoDiscount, totalBundlePromoDiscount, totalItem} = useCart();
    const [mobileNav, setMobileNav] = useState(false);
    const [cart, showCart] = useState(false);
    const [getItemsCount , setItemsCount ] = useState(0);
    //const [totalPrice, setTotalPrice] = useState(0);
    const [totalDiscount, setTotalDiscount] = useState(0);
    const [changeColumnsProducts, setChangeColumnsProducts] = useState([]);
    const handleCloseMobileNav = () => {
        setMobileNav(false);
    };
    useEffect(()=>{
        if(!cartItems) return
        const tDiscount = parseFloat(totalPromoDiscount) + parseFloat(totalBundlePromoDiscount);
        setTotalDiscount(tDiscount);
        setItemsCount(totalItem);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[cartItems]);
    const viewCart = () => {
      let ccp = cartItems.map((item, i) => {
          const {
              promo: price,
              make: item_brand,
              slug: location_id,
              tire_type: item_category,
              ...rest
          } = item;
          return {
              index: i,
              currency: 'PHP',
              location_id,
              price,
              item_brand,
              item_category,
              ...rest
          }
      });
      setChangeColumnsProducts(ccp);
      let changeColumnsCarts = cartItems.map(item => {
          const unit_price = item.sale_tag == 1 ? item.promo : item.srp;
          const discount_price = item.sale_tag == 1 ? item.srp - item.promo : 0;

          return {
              item_id: item.id,
              item_brand: item.make,
              item_name: item.model,
              item_category: item.tire_type,
              quantity: item.quantity,
              unit_price: unit_price,
              discount_price: discount_price,
              affiliation: "Gulong Web Store",
              currency: "PHP"
          }
      });
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
          event: "view_cart",
          currency : "PHP",
          value : totalAmount,
          ecommerce: {
              items: changeColumnsCarts
          }
      });

    };
    return(
        <div className={`${(router.route == "/product/[name]") ? 'absolute' : 'fixed'} top-0 left-0 w-full z-50`}>
            <AdsBanner/>
            <div className={`${(router.route == "/product/[name]") ? 'pt-[35px]' : ''} py-1 shadow bg-white`}>
                <nav className="max-w-screen-lg mx-auto flex flex-row items-center justify-between lg:px-4 px-2">
                    <div className="lg:hidden flex flex-row">
                        <button className="text-red-600 w-10 h-10 relative group focus:outline-none bg-white shadow shadow-red-100 rounded" onClick={()=> setMobileNav(!mobileNav)}>
                            <span className="sr-only">Open menu</span>
                            <div className="block w-6 absolute left-1/2 top-1/2  transform  -translate-x-1/2 -translate-y-1/2">
                                <span aria-hidden="true" className={`block absolute h-0.5 w-6 bg-red-500 group-hover:bg-blue-600 transform transition duration-500 ease-in-out ${mobileNav ? 'bg-blue-600 rotate-45' : '-translate-y-1.5'}`}></span>
                                <span aria-hidden="true" className={`block absolute h-0.5 w-6 bg-red-500 group-hover:bg-blue-600 transform transition duration-500 ease-in-out ${mobileNav ? 'opacity-0' : ''}`}></span>
                                <span aria-hidden="true" className={`block absolute h-0.5 w-6 bg-red-500 group-hover:bg-blue-600 transform transition duration-500 ease-in-out ${mobileNav ? 'bg-blue-600 -rotate-45' : 'translate-y-1.5'}`}></span>
                            </div>
                        </button>
                    </div>
                    <Link className="relative" href="/">
                        <div className="lg:w-52 w-40 py-px">
                            <Image
                                src={'/assets/images/logo.webp'}
                                alt={'gulong-ph-logo'}
                                width={1000}
                                height={1000}
                                priority
                            />
                        </div>
                    </Link>
                    <div className="hidden lg:flex flex-col w-full">
                        <ul className="w-full flex flex-row justify-evenly text-center">
                            {navLinks.map((nav, index) => {
                                if(nav.name != "Shop"){
                                    return(
                                        <li key={index}
                                            className={`pb-2 tracking-wide relative nav-link hover:text-red-600 font-semibold ${nav.url == router.pathname ? `nav-link-active text-red-600` : 'text-gray-700 border-white'}`}>
                                            <Link href={nav.url}>
                                                {nav.name}
                                            </Link>
                                        </li>
                                    );
                                }
                                else{
                                    return(
                                        <li key={index}
                                            className={`pb-2 relative nav-link hover:text-red-600 font-semibold ${nav.url == router.pathname ? `nav-link-active text-red-600` : 'text-gray-700 border-white'}`}>
                                            <div className="group inline-block relative cursor-pointer">
                                                <span className="flex flex-row items-center w-full whitespace-nowrap focus:outline-none">
                                                    {nav.name}
                                                    <ExpandMoreIcon
                                                        className="w-5 h-5 lg:ml-2 ml-1"
                                                        aria-hidden="true"
                                                    />
                                                </span>
                                                <ul className="absolute border border-gray-100 hidden min-w-min rounded bg-white group-hover:block z-50">
                                                    <li className="w-full relative rounded-b">
                                                        <a onClick={()=> router.push("/shop")} className="cursor-pointer flex flex-row items-center pr-8 hover:bg-red-400 hover:text-white py-1 px-4 whitespace-nowrap">
                                                            All Product
                                                        </a>
                                                    </li>
                                                    <li className="w-full group/cat border-b border-gray-100 relative rounded-t">
                                                        <a className={`flex flex-row items-center pr-8 hover:bg-red-400 hover:text-white py-1 px-4 whitespace-nowrap`}>
                                                            Car Type
                                                            <ExpandMoreIcon
                                                                className="absolute top-50 right-0 w-5 h-5 group-hover/cat:-rotate-90 !transition-all"
                                                                aria-hidden="true"
                                                            />
                                                        </a>
                                                        <ul className="absolute -space-y-1 -top-px left-[97.5%] hidden min-w-min rounded group-hover/cat:block">
                                                            {car_type.map((item, index) => {
                                                                let first_link = (index === 0) ? 'rounded-t': '';
                                                                let last_link = (car_type.length === index + 1) ? 'rounded-b': '';
                                                                return (
                                                                    <li key={item.id} className={`${first_link+' '+last_link} w-full ml-1 bg-white border border-gray-100`}>
                                                                        <a onClick={()=> router.push("/shop?c="+item.category, undefined, { shallow: true })} className="cursor-pointer hover:bg-red-400 hover:text-white py-1 px-4 block text-base whitespace-nowrap gulong-font">
                                                                                {item.category}
                                                                        </a>
                                                                    </li>
                                                                );
                                                            })}
                                                        </ul>
                                                    </li>
                                                    <li className="w-full group/sub relative rounded-b">
                                                        <a className={`flex flex-row items-center pr-8 hover:bg-red-400 hover:text-white py-1 px-4 whitespace-nowrap`}>
                                                            Price Category
                                                            <ExpandMoreIcon
                                                                className="absolute top-50 right-0 w-5 h-5 group-hover/sub:-rotate-90 !transition-all"
                                                                aria-hidden="true"
                                                            />
                                                        </a>
                                                        <ul className="absolute -space-y-1 -top-px left-[97.5%] hidden min-w-min rounded  group-hover/sub:block">
                                                            {tires_brand.map((brand, index) => {
                                                                let first_link = (index === 0) ? 'rounded-t': '';
                                                                let last_link = (tires_brand.length === index + 1) ? 'rounded-b': '';
                                                                let brand_slug = brand.slug.toUpperCase();
                                                                return (
                                                                    <li key={index} name="tbrand" value={brand.name} className={`${first_link+' '+last_link} w-full ml-1 bg-white border border-gray-100`}>
                                                                        <a onClick={()=> router.push("/shop?tc="+brand_slug, undefined, { shallow: true })} className="cursor-pointer hover:bg-red-400 hover:text-white py-1 px-4 block text-base whitespace-nowrap gulong-font">
                                                                            {brand.name}
                                                                        </a>
                                                                    </li>
                                                                );
                                                            })}
                                                        </ul>
                                                    </li>
                                                </ul>
                                            </div>
                                        </li>
                                    )
                                }
                            })}
                        </ul>
                        <div className="flex flex-row">
                            <SearchBox className="w-full bg-white relative pl-6" />
                            <a aria-label="show cart" className="cursor-pointer group" onClick={()=> showCart(true)}>
                                <IconButton className="px-0 pb-0 !pt-px" aria-label={`${getItemsCount ? getItemsCount : 0} ${(getItemsCount > 1) ? 'items' : 'item' } in the cart`}>
                                    <Badge badgeContent={getItemsCount ? getItemsCount : 0} color="secondary">
                                        <ShoppingCartIcon className="text-red-600 group-hover:text-red-800 transition duration-500 mt-px" fontSize={"large"}/>
                                    </Badge>
                                </IconButton>
                            </a>
                        </div>
                    </div>
                    <div className="pr-2">
                        <a aria-label="show cart" className="btnCart lg:hidden block w-10 h-10 group" onClick={()=> showCart(true)}>
                            <IconButton className="pl-0 pr-0 pb-0 pt-1" aria-label={`${getItemsCount ? getItemsCount : 0} ${(getItemsCount > 1) ? 'items' : 'item' } in the cart`}>
                                <Badge badgeContent={getItemsCount ? getItemsCount : 0} color="secondary">
                                    <ShoppingCartIcon className="text-red-600 group-hover:text-red-800 transition duration-500" fontSize={"large"}/>
                                </Badge>
                            </IconButton>
                        </a>
                    </div>
                </nav>
                {mobileNav && (
                    <MobileNav
                        mobileNav={mobileNav}
                        handleCloseMobileNav={handleCloseMobileNav}
                        mobileNavigation={mobileNavigation}
                        carType={car_type}
                        tiresBrand={tires_brand}
                        getItemsCount={getItemsCount}
                        showCart={showCart}
                    />
                )}
                <SearchBox className="lg:hidden block w-full bg-white mt-2 relative lg:px-4 px-2 z-[60]" />
            </div>
            <SwipeableDrawer
                anchor={"right"}
                open={cart}
                onClose={()=>showCart(false)}
                onOpen={()=>showCart(true)}
            >
                <div className="sm:w-[28rem] w-screen relative flex flex-col">
                    <div className="flex flex-row align-middle items-center border-b border-red-100 px-2 pb-1">
                        <div className="relative block w-40 h-16 ml-4">
                            <Image
                                src="/assets/images/gulong_new_logo_low_res.webp"
                                alt="GulongPH logo"
                                fill
                                sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
                            />
                        </div>
                        <a
                            onClick={()=>showCart(false)}
                            className="select-none cursor-pointer flex flex-row align-middle items-center justify-center text-red-600 bg-red-50 border border-red-200 rounded-full hover:bg-red-600 hover:text-white hover:scale-95 duration-200 ease-linear transition w-10 h-10 my-2 ml-auto">
                            <CloseIcon fontSize="small"/>
                        </a>
                    </div>
                    {getItemsCount > 0 ? (
                        <Fragment>
                            <div className="px-2 pb-[119px]">
                                <Cart showCart={showCart}/>
                            </div>
                            <div className="fixed w-[inherit] bottom-[73px] px-2 py-2 bg-white border-t border-red-100">
                                {parseFloat(totalDiscount) > 0 && (
                                    <Fragment>
                                        <div className="flex flex-row justify-between px-2">
                                            <h1 className="font-semibold text-gray-700">Subtotal:</h1>
                                            <NumericFormat className="font-semibold" value={totalAmount.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'₱'} decimalScale={2} />
                                        </div>
                                        <div className="px-2 border-b border-red-100 mb-1">
                                            <h1 className="font-semibold text-gray-700">Discount:</h1>
                                            <div className="lg:pl-6 pl-2">
                                                {(parseFloat(totalPromoDiscount) > 0) && (
                                                    <div className="flex flex-row justify-between">
                                                        <label className="mt-px font-semibold flex align-middle justify-center items-center text-gray-600">
                                                            <svg className="w-6 h-6 mt-px mr-1 text-blue-500" xmlns="http://www.w3.org/2000/svg" width="256" height="256" viewBox="0 0 256 256"><g fill="currentColor"><path d="M200 128a32 32 0 0 0 25.6 31.36a8 8 0 0 1 6.4 7.83V192a8 8 0 0 1-8 8H96V56h128a8 8 0 0 1 8 8v24.81a8 8 0 0 1-6.4 7.83A32 32 0 0 0 200 128Z" opacity=".2"/><path d="M227.19 104.48A16 16 0 0 0 240 88.81V64a16 16 0 0 0-16-16H32a16 16 0 0 0-16 16v24.81a16 16 0 0 0 12.81 15.67a24 24 0 0 1 0 47A16 16 0 0 0 16 167.19V192a16 16 0 0 0 16 16h192a16 16 0 0 0 16-16v-24.81a16 16 0 0 0-12.81-15.67a24 24 0 0 1 0-47ZM32 167.2a40 40 0 0 0 0-78.39V64h56v128H32Zm192 0V192H104V64h120v24.8a40 40 0 0 0 0 78.39Z"/></g></svg>
                                                            Buy 3, Get 4th Free Discount
                                                        </label>
                                                        <NumericFormat className="font-semibold text-red-500" value={(-1 * parseFloat(totalPromoDiscount)).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'₱'} decimalScale={2} />
                                                    </div>
                                                )}
                                                {(parseFloat(totalBundlePromoDiscount) > 0) && (
                                                    <div className="flex flex-row justify-between">
                                                        <label className="mt-px font-semibold flex align-middle justify-center items-center text-gray-600">
                                                            <svg className="w-6 h-6 mt-px mr-1 text-blue-500" xmlns="http://www.w3.org/2000/svg" width="256" height="256" viewBox="0 0 256 256"><g fill="currentColor"><path d="M200 128a32 32 0 0 0 25.6 31.36a8 8 0 0 1 6.4 7.83V192a8 8 0 0 1-8 8H96V56h128a8 8 0 0 1 8 8v24.81a8 8 0 0 1-6.4 7.83A32 32 0 0 0 200 128Z" opacity=".2"/><path d="M227.19 104.48A16 16 0 0 0 240 88.81V64a16 16 0 0 0-16-16H32a16 16 0 0 0-16 16v24.81a16 16 0 0 0 12.81 15.67a24 24 0 0 1 0 47A16 16 0 0 0 16 167.19V192a16 16 0 0 0 16 16h192a16 16 0 0 0 16-16v-24.81a16 16 0 0 0-12.81-15.67a24 24 0 0 1 0-47ZM32 167.2a40 40 0 0 0 0-78.39V64h56v128H32Zm192 0V192H104V64h120v24.8a40 40 0 0 0 0 78.39Z"/></g></svg>
                                                            Bundle Promo
                                                        </label>
                                                        <NumericFormat className="font-semibold text-red-500" value={(-1 * parseFloat(totalBundlePromoDiscount)).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'₱'} decimalScale={2} />
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </Fragment>
                                )}
                                <div className="flex flex-row justify-between px-2">
                                    <h1 className="text-xl font-semibold text-gray-700">Total:</h1>
                                    <NumericFormat className="font-semibold text-xl" value={(totalAmount - parseFloat(totalDiscount)).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'₱'} decimalScale={2} />
                                </div>
                            </div>
                            <div className="fixed w-[inherit] bottom-0 px-2 py-2 bg-white border-t border-red-100">
                                <button type="button"
                                    onClick={()=>{
                                        router.push("/checkout?step=appointment");
                                        viewCart();
                                    }}
                                    className="view-cart-button"
                                >
                                    View Cart
                                </button>
                            </div>
                        </Fragment>
                    ) : (
                        <Fragment>
                            <div className="flex flex-col justify-center text-center">
                                <h1 className="text-gray-300 text-xl font-semibold mt-[40%]">Your shopping cart is empty</h1>
                                <ShoppingCartIcon className="text-gray-200 w-48 h-48 mx-auto" fontSize="large"/>
                            </div>
                            <div className="fixed w-[inherit] bottom-0 px-2 py-2 bg-white border-t border-red-100">
                                <button type="button"
                                    onClick={()=>{
                                        router.push("/shop");
                                        showCart(false);
                                    }}
                                    className="w-full bg-red-600 text-white border border-red-400 hover:text-red-600 hover:bg-white hover:scale-x-95 font-semibold text-xl rounded h-14 uppercase duration-200 ease-linear transition"
                                >
                                    Shop For Tires
                                </button>
                            </div>
                        </Fragment>
                    )}
                </div>
            </SwipeableDrawer>
        </div>
    )
}
